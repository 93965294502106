@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('../theme/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Inter-SemiBold.woff2') format('woff2');
}

/* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2');
}
/* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2');
}
/* montserrat-500italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2') format('woff2');
}
/* montserrat-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2');
}
/* montserrat-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2');
}
/* montserrat-900 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/montserrat-v18-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2');
}


@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('../theme/fonts/OpenSans/OpenSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/OpenSans/OpenSans-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/OpenSans/OpenSans-SemiBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/OpenSans/OpenSans-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('ttf');
}

@font-face {
  font-family: 'HartWell';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Hartwell-Bold.woff2') format('woff2');
}